@font-face {
  font-family: SpoqaB;
  font-style: normal;
  src: local('Spoqa Han Sans Neo Bold'), local('SpoqaHanSansNeo-Bold'), local('SpoqaB'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.otf) format('opentype'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf) format('truetype'),
    /* IE 호환성 보기 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.eot),
    /* IE 6 ~ 8 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.eot?#iefix) format('embedded-opentype');
}
@font-face {
  font-family: SpoqaM;
  font-style: normal;
  src: local('Spoqa Han Sans Neo Medium'), local('SpoqaHanSansNeo-Medium'), local('SpoqaM'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.otf) format('opentype'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf) format('truetype'),
    /* IE 호환성 보기 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.eot),
    /* IE 6 ~ 8 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.eot?#iefix) format('embedded-opentype');
}
@font-face {
  font-family: SpoqaR;
  font-style: normal;
  src: local('Spoqa Han Sans Neo Regular'), local('SpoqaHanSansNeo-Regular'), local('SpoqaR'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.otf) format('opentype'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf) format('truetype'),
    /* IE 호환성 보기 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.eot),
    /* IE 6 ~ 8 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.eot?#iefix) format('embedded-opentype');
}
@font-face {
  font-family: SpoqaL;
  font-style: normal;
  src: local('Spoqa Han Sans Neo Light'), local('SpoqaHanSansNeo-Light'), local('SpoqaL'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.otf) format('opentype'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf) format('truetype'),
    /* IE 호환성 보기 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.eot),
    /* IE 6 ~ 8 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.eot?#iefix) format('embedded-opentype');
}
@font-face {
  font-family: SpoqaT;
  font-style: normal;
  src: local('Spoqa Han Sans Neo Thin'), local('SpoqaHanSansNeo-Thin'), local('SpoqaT'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2) format('woff'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.otf) format('opentype'),
    url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf) format('truetype'),
    /* IE 호환성 보기 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.eot),
    /* IE 6 ~ 8 */ url(/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.eot?#iefix) format('embedded-opentype');
}
@font-face {
  font-family: MontserratL;
  font-style: normal;
  src: local('Montserrat-Light'), local('MontserratL'),
    url(/fonts/Montserrat-Light.ttf) format('truetype');
}
@font-face {
  font-family: MontserratR;
  font-style: normal;
  src: local('Montserrat-Regular'), local('MontserratR'),
    url(/fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: MontserratM;
  font-style: normal;
  src: local('Montserrat-Medium'), local('MontserratM'),
    url(/fonts/Montserrat-Medium.ttf) format('truetype');
}
