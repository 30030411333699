@import './color.scss';

:root {
    --swiper-theme-color: var(--blue105);
}

.swiper-container {
    width: inherit;
    margin-bottom: 20px;
    padding: 10px 0;
    // @media screen and (max-width: 420px) {
    //     margin-top: -20px;
    // }
    .swiper-wrapper {
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }
    .swiper-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        position: relative;
        // margin: 0 25px 0 5px;
    }
}

.swiper-button-prev, .swiper-button-next{
    width: 30px;
    top: 95px;
}

.swiper-button-prev {
    margin-left: 35px;
    @media screen and (max-width: 420px) {
        margin-left: 15px;
    }
}
.swiper-button-next {
    margin-right: 35px;
    @media screen and (max-width: 420px) {
        margin-right: 15px;
    }
}

.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 20px;
}
