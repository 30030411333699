:root {
    --primary: #042659;

    --white: #ffffff;
    --gray01: #eeeeee;
    --gray02: #dbdbdb;
    --gray03: #9c9b9b;
    --gray04: #747474;
    --black: #121619;

    
    --blue100: #f5f7fb;
    --blue101: #dee9ff;
    --blue102: #c8dbfd;
    --blue104: #93baff;
    --blue105: #2D76F8;
    --blue106: #002f77;
    

    --red: #fa4e57;

    --orange: #ff7a00;
}
